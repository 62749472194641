
.testimonial-page-container hr{
    margin: 10px 0px;
    color: red;
}
.testimonial-page-container .link{
    text-decoration: none;
    color: black;
    font-weight: 600;
}
.testimonial-page-container .link:hover{
    color: blue;
}
.testimonial-page-container .review-card{
    padding: 15px;
    opacity: .8;
    border-radius: 0px;
}
.testimonial-page-container .property-card{
    background-color: rgb(243, 232, 232);
    padding: 15px;
    opacity: .8;
    border-radius: 0px;
}
.testimonial-page-container .card i{
    color: rgb(6, 6, 150);
}
.testimonial{
    border: 1px solid rgba(0, 0, 0, .5);
    border-radius: 5px;
    background-color:#0794f3;
    font-weight: bold;
    padding: 7px 6px;
}
.testimonial:hover{
    background-color: rgb(7, 7, 7);
    color: white;
}
.testimonial-star{
    color: red;
    font-size: 8px;
}


#my-form table input[type="text"],
#my-form table input[type="email"],
#my-form table textarea,
#my-form table select{
    border: 1px solid rgb(201, 197, 197);
}
#my-form table input[type="text"]:focus,
#my-form table input[type="email"]:focus,
#my-form table textarea:focus,
#my-form table select:focus
{
    outline: none;
    border: none;

}



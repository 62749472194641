@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}



.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  padding: 90px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}
.login-form{
  background: #d6dbdf !important;
}
.auth-inner {
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 70px 100px 70px 100px;
  border-radius: 5px;
  transition: all 0.3s;
}
.auth-inner label{
  font-size: 15px;
}
.auth-inner input[type="email"]{
  padding: 0px 10px;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

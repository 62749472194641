* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.curre

.card-body .property-details {
    font-size: 14px;
    font-weight: 500;
}

.location {
    font-size: 13px;
}

.property {
    background: url("../../../public/Image/available_property.png");
}
.property .card:hover{
    text-decoration: none;
}


.available-property {
    color: orangered;
    font-size: 25px;
    font-weight: 500;
}

.property .card {
    border-radius: 0px;
    height: 100%;
}
.property .card .card-img-top{
    width: 100%;
    height: 20vw;
    object-fit: cover;
    opacity: .9;
}
.property .card-footer{
    background-color:orange;
    color: rgb(32, 8, 240);
    font-weight: 500;
    font-size: 14px;
}
.property .card .card-body h6{
    font-size: 14px;
    color: rgba(0, 0, 0, .9);
    font-weight: 500;
}
@media screen and (max-width:992px) {
    .property-details{
        margin-top: 30px;
    }
    .testimonial-col{
        margin: 10px 0px;
    }
}
@media screen and (max-width:768px) {
    .service-card{
        margin-top: 15px;
    }
    .values-col{
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media screen and (max-width:576px) {
    .property .card .card-img-top{
        width: 100%;
        height: 40vw;
        object-fit: cover;
    }
}

.link {
    text-decoration: none;
}
.link:hover{
    text-decoration: none;
}

/* Our vision */

.our-vision {
    padding: 3rem 0;
    background: url("../../../public/Image/vision-bg.png") fixed;
}

.our-vision .card {
    padding: 3.5rem .5rem;
    text-align: center;
    height: 100%;
}

.our-vision .card-title {
    margin-top: 3rem;
    text-align: center;
    color: blue;
    opacity: .9;
}

.our-vision .card-text {
    margin-left: 15px;
    margin-right: 15px;
    opacity: .9;
}

@media screen and (max-width:576px) {
    .our-vision .column {
        margin-top: 25px;
    }

    .property .column,
    .service-card {
        margin-top: 25px;
    }
}

/* our services */
.service-head {
    color: orangered;
    font-weight: 500;
    margin: 1.5rem 0;
}

.our-service {
    background: url("../../../public/Image/service-bg.jpg") repeat;
    padding-bottom: 4rem;
}

.our-service .service-link {
    text-decoration: none;
    transition: .3s ease;
}

.our-service .card-title {
    font-size: 17px;
    font-weight: 600;
    opacity: .8;
    text-transform: uppercase;
}

.our-service .card-text {
    opacity: .9;
    color: black;
}

.our-service .card-footer {
    font-size: 15px;
    color: blue;
    opacity: .9;
}

.service-card:hover .card{
    background-color:orange;
}

.service-card:hover {

    .card-text,
    .card-footer {
        color: rgba(255, 255, 255, 1);
    }
    .service-link img{
        box-shadow: 0px 1px 2px 2px rgb(7, 7, 7);
    }
}
.service-card .card{
    height: 100%;
    border-radius: 0px;
    padding: 20px 0px;
}
.service-card .card .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.service-link img {
    width: 4.2rem;
    height: 4rem;
    border-radius: 5px;
    box-shadow: 0px 1px 2px 2px orangered;
    margin: 10px 0px 0px 10px;
}

@media screen and (max-width:320px) {
    .service-card .card .header{
        display: block;
    }
    .service-card .card .header .card-title{
        margin-left: 10px;
    }
}
@media screen and (max-width:992px) {
    .service-card .card .header .card-title{
        margin-left: 2rem;
        font-size: 15px;
    }
}

/* About-container */

.about-container,
.about-container .card {
    background-color: rgb(99, 85, 228);
}

.about-container .card {
    border: none;
}

.dreamland-head {
    font-size: 1.9rem;
    font-weight: 500;
    color: orange;
}

.dreamland-para {
    color: white;
    margin-top: 25px;
    opacity: .9;
    line-height: 1.5rem;
}
@media screen and (max-width:992px) {
    .about-container .card{
        margin-top: 60px;
    }
    .about-container img{
        border-radius: 50%;
    }
    .about-container .about-rl .dreamland-head{
        margin-top: 50px;
    }
}
@media screen and (max-width:768px) {
    .about-container .card{
        margin-top: 200px;
    }
}
@media screen and (max-width:576px) {
    .about-container .card{
        margin-top: 20px;
    }
}
@media screen and (min-width:992px) {
    .about-container .about-rl{
        margin-top: 130px;
    }
}

/* Testimonials */
.testimonial-container {
    background: url("../../../public/Image/available_property.png") fixed;
    padding-bottom: 2.5rem;
}

.testimonial-head {
    color: orangered;
    opacity: .8;
    font-size: 26px;
    font-weight: 500;
}

.testimonial-container .card {
    padding: 1.5rem 1rem 1.2rem 1rem;
    height: 100%;
    width: 100%;
    color: white;
    text-align: center;
    background-color: rgb(245, 66, 66);
}

.testimonial-container .card p {
    margin-top: 30px;
    opacity: .9;
}

.testimonial-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.read-more{
    position: relative;
    text-decoration: none;
    color: black;
    font-weight: 500;
}
.read-more::after{
    content: "";
    position: absolute;
    left: 0;
    left: 0;
    bottom: 0;
    background: #fff;
    width: 0;
    height: 1px;
    text-shadow: 2px 2px 2px #070c11;
    transition: 0.35s cubic-bezier(0.17, 0.67, 0.5, 1.03);
}
.read-more:hover::after{
    text-decoration: none;
    width: 100%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}
.read-more:hover{
    text-decoration: none;
    color: black;
    font-weight: 700;
}

.quotes-icon {
    padding: 16px 0px;
    border-radius: 50%;
    font-size: 1.5rem;
    color: black;
    background-color: white;
}

.vision-icon {
    color: orangered;
    font-size: 3rem;
}

.testimonial-row .card-text {
    margin-top: 1.5rem;
}

/* Enquiry css */

* {
    padding: 0;
    margin: 0;
}

.enquiry-container{
    
    overflow: visible;
    /* margin-top: 2.5rem; */
    padding: 1.5rem 1rem;
}


.enquiry-bg{
    background: url("../../../public/Image/background3.jpg");
}

.enquiry-head{
    color: rgb(57, 57, 230);
    opacity: .8;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 600;
    text-decoration: none;
}
.clearfix:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
}

.form_wrapper {
    background: #fff;
    box-sizing: border-box;
    padding: 15px;
    margin: 4% auto 0;
    position: relative;
    z-index: 1;
    margin-bottom: 15px;
}

.form_container {
    padding: 15px;
    border: 1px dashed #ccc;
}

.form_wrapper h2 {
    font-size: 1.5em;
    line-height: 1.5em;
    margin: 0;
}

.form_wrapper .title_container {
    text-align: center;
    /* margin: -15px -15px 15px; */
    padding: 15px 0;
    border-bottom: 1px dashed #ccc;
}

.form_wrapper h3 {
    font-size: 1.1em;
    font-weight: normal;
    line-height: 1.5em;
    margin: 0;
}

.form_wrapper .row {
    margin: 10px -15px;
}

.form_wrapper .row>div {
    padding: 0 15px;
    box-sizing: border-box;
}

.form_wrapper .col_half {
    width: 50%;
    float: left;
}

.form_wrapper label {
    display: block;
    margin: 0 0 5px;
}

.form_wrapper .input_field,
.form_wrapper .textarea_field {
    position: relative;
}

.form_wrapper .input_field>span,
.form_wrapper .textarea_field>span {
    position: absolute;
    left: 0;
    top: 0;
    color: #333;
    height: 100%;
    border-right: 1px solid #ccc;
    text-align: center;
    padding: 0 8px;
}

.form_wrapper .textarea_field>span {
    border-bottom: 1px solid #ccc;
    max-height: 35px;
}

.form_wrapper .input_field>span>i,
.form_wrapper .textarea_field>span>i {
    padding-top: 12px;
}

.col_half input[type="text"],
.col_half input[type="email"],
.col_half input[type="tel"],
.col_half select,
.clearfix textarea {
    width: 100%;
    padding: 10px 10px 10px 35px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    outline: none;
    transition: all 0.30s ease-in-out;
}

.form_wrapper textarea {
    height: 7.2em;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
select:focus,
textarea:focus {
    box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
    border: 1px solid #f5ba1a;
}

.form_wrapper input[type="submit"] {
    background:rgb(57, 57, 230);
    height: 50px;
    line-height: 50px;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 1.2em;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    transition: all 0.30s ease-in-out;
}

.form_wrapper input[type="submit"]:hover,
.form_wrapper input[type="submit"]:focus {
    background: #daa106;
}

.credit {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 15px;
    color: #f5ba1a;
}

.credit a {
    color: #daa106;
}

@media (max-width: 600px) {
    .form_wrapper .col_half {
        width: 100%;
        float: none;
    }

    .form_wrapper label {
        margin: 10px 0;
    }
}

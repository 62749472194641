.d1{
    width: 50%;
}
.toggled{
    overflow: visible;
    width: 9rem!important;
    transition: all 0.4s ease-in-out;
}
.toggled1{
    width: 0!important;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
}
.btn-link{
  border: none;
  animation: gradients 9s ease infinite;
}
@keyframes gradients {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


/* {pages css} */


.property-form label{
  color: rgb(14, 14, 177);
}
.property-form span{
  color: rgb(14, 14, 177);
}
.dashboard .nav-link{
    font-size: 14px;
    font-weight: 600;
    color: white;
}
.wrapper span:hover{
  color: orange;
}
.dashboard .nav-item{
    padding: 0px 5px;
}
.dashboard .nav-item:hover{
    color: white;
    border-radius: 5px;
}
.dashboard .nav-item:hover .nav-link{
    color: orange;
}
.dashboard .nav-item:hover i{
    color: orange;
}
.dashboard span{
    font-size: 22px;
    font-weight: 600;
    font-family:monospace;
}
.dhome,.toggle-bar {
  background: url("../../../public/Image/background3.jpg");
  margin-top: 0px!important;
}
.dhome .card{
    border-radius: 8px;
    padding: 20px 0px;
}
.dhome .card h5{
    font-size: 2vm;
}
.dhome i{
    background-color: blue;
    padding: 5px 8px;
    margin-top: 20px;
    border-radius: 5px;
}
@media screen and (max-width:768px) {
    .dhome .card{
        margin-top: 1rem;
    }
}

/* AddProperty */
.addProperty form label{
    font-size: 14px;
    font-weight: 600;
}
.addProperty form{
    padding: 15px 20px;
    box-shadow: 0px 1px 3px 2px orange;
    color: black ;
    background-color:  white;
}
.addProperty input:focus{
    border: 1px solid red;
}
.addProperty h4{
    font-size: 22px;
    text-align: center;
    font-weight: 600;
    color: #004D40;
}

/* preloader css */

.loading-page {
    width: 100%;
    height: 100vh;
    background-color: rgb(7, 71, 166);
  }
  .loading-page .center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 47%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    display: inline-block;
  }

.sidebar-divider{
  height: 1px;
  opacity: 1;
}
  
  .slidebar {
    background: linear-gradient(45deg, #02e1ba 0%,#26c9f2 29%,#d911f2 66%,#ffa079 100%);
    background-size: 400% 400%;
	animation: Gradient 15s ease infinite;
}

.slidebar::before, 
.slidebar::after {
	background: rgba(255, 255, 255, 0.07);
	animation: morph 15s linear infinite alternate, spin 20s linear infinite;
	z-index: 1;
	will-change: border-radius, transform;
	transform-origin: 55% 55%;
	pointer-events: none; 
}
	
.slidebar::after {
    
    animation: morph 10s linear infinite alternate, spin 26s linear infinite reverse;
    transform-origin: 20% 20%; 
}

@keyframes Gradient {
	0% {
		background-position: 0 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0 50%
	}
}

@keyframes morph {
  0% {
    border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%; }
  100% {
    border-radius: 40% 60%; } 
}

@keyframes spin {
  to {
    transform: rotate(1turn); 
  } 
}
	.st0{display:none;}
	.st1{display:inline;}
	.st2{opacity:0.29;}
	.st3{fill:#FFFFFF;}
	.st4{clip-path:url(#SVGID_2_);fill:#FFFFFF;}
	.st5{clip-path:url(#SVGID_4_);}
	.st6{clip-path:url(#SVGID_6_);}
	.st7{clip-path:url(#SVGID_8_);}
	.st8{clip-path:url(#SVGID_10_);}
	.st9{fill:none;}
	.st10{clip-path:url(#SVGID_12_);}
	.st11{opacity:0.7;}
	.st12{clip-path:url(#SVGID_14_);}
	.st13{opacity:0.2;}
	.st14{clip-path:url(#SVGID_16_);}
	.st15{opacity:0.3;fill:#FFFFFF;enable-background:new    ;}
    
  .slidebar i{
    color: white;
  }
  input[type=text],input[type=file]{
    font-size: 14px;
  }

   .mobile-menu-icon i.close {
    display: none;
    margin-top: 25px;
  }
  .mobile-menu-icon i.open{
    margin-top: 15px;
  }

  @media (max-width:768px) {
    .slidebar {
      display: none;
    }
  } 


  /* Dashboard-home css */

  .dashboard-home .card{
    /* background-color: rgb(15, 248, 248); */
    background-color:  rgb(6, 99, 238);
  }
  #page-top span{
    font-size: 14px;
    font-family:Arial, Helvetica, sans-serif;
  }




  
.popup-button{
    position: fixed;
    top: 83%;
    right: 15px;
    left: auto;
    z-index: 1000;
    padding: 2px 0px;
    border-radius: 50%;
    cursor: pointer;
    
}
.popup-button button{
    color: white;
}
.popup-button button:hover{
    color: white;
}
.popup-button button:focus{
    border: none;
}

#popup-form{
    border-radius: 8px;
    padding: 20px;
    background: linear-gradient(to bottom, rgb(12, 155, 12) 50%, rgb(252, 253, 252) 50%);
    position: fixed;
    box-shadow: 0px 0px 10px 0px black;
    top: 43%;
    right: 35px;
    z-index: 1000;
}
.popup-head{
    padding: 0px 0px 1.6rem 2px;
    color: white;
}
.popup-head i{
    position: absolute;
    right:3%;
    top: 3%;
    font-size: 1.5rem;
    cursor: pointer;
}
.head{
    font-size: 1.2rem;
    font-weight: 600;
}
.pop-button{
    outline: 0;
    width: 45px;
    height: 45px;
    border: 0;
    background-color: #0bc257;
    padding: 0;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    cursor: pointer;
    transition: opacity .3s,background .3s,box-shadow .3s;
    animation: bounce 4s infinite alternate;
}
.pop-button::after{
    content: '';
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 48%;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 1;
    background-image: url("../../../public/Image/whatsapp.png");
}

.foot{
    background-color:#e8edf3;
    border-left: 2px solid green;
    border-radius: 2px 4px 2px 4px;
}
.foot:hover{
    background-color:#e8edf3;
    border-left: 2px solid green;
}


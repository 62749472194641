.scroll-button{
    position: fixed;
    top: 92%;
    right: 20px;
    left: auto;
    z-index: 1000;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color:white;
    background-color:rgb(250, 178, 44);
    box-shadow: 0px 0px 5px 1px black;
}
.arrow-up{
    animation:scroll 2.3s infinite ease;
}
@keyframes scroll {
    0%{transform: translateY(0px);}
    50%{transform: translateY(5px);}
    100%{transform: translateY(-5px);}
}
/* .project1-container .container-fluid{
    position: relative;
    text-align: center;
} */
body{
    background-color: rgb(243, 232, 232);
}

.overview{
    background: url("../../../../public/Image/available_property.png");
    padding-bottom: 1rem;
}
 .overview h4,.facility h4,.features-container h4,.project-feature h4{
    font-size: 2rem;
    font-family:'Times New Roman', Times, serif;
    color: rgb(241, 125, 82);
}

/* Neighbourhood */

.neighbour img{
    transition: all ease-in-out .7s;
}

/* facilities */

.facility img{
    transition: all ease-in-out 1.4s;
}
.facility .icon:hover img{
    transform: scale(1.5, 1.5);
}
.icon{
    overflow: hidden;
}

/* Features */
.content{
    margin-left: 10px;
}
.features-container{
    background-color: white;
    padding: 1.6rem 0;
}

.features-container .features-main{
    display: flex;
}
.features-main h5{
    font-size: 14px;
    font-weight: 600;
    opacity: .8;
}
.features-main p{
    font-size: 13px;
    opacity: .9;
}

/* project features */

.project-feature img{
    transition: all ease-in-out 1.4s;
}
.project-feature .icon:hover img{
    transform: scale(1.5, 1.5);
}
/* {image overlay} */

.project-img,.icon{
    position: relative;
    overflow: hidden;
}
.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(79, 24, 231, 0.42);
    /* background-color: rgba(248, 172, 32, 0.4); */
    overflow: hidden;
    width: 100%;
    height: 100%;
    transition: .5s ease;
}
.overlay1 {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    transition: .5s ease;
}

.right-overlay {
    left: 100%;
    height: 100%;
}

.project-img:hover .right-overlay {
  left : 0;
}
.left-overlay {
    right: 100%;
    height: 100%;
    left:auto;
}

.icon:hover .left-overlay {
  right : 0;
}
.text {
    color: white;
    font-size: 5vw;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
@media screen and (max-width:992px) {
    .videos{
        margin-top: 100px;
    }
}
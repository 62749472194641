/* @import url('https://fonts.cdnfonts.com/css/gotham'); */


.start {
  color: white;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.item {
  text-decoration: none;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, .8);
  font-weight: 400;
}
.navbar {
  background-color: white;
  box-shadow: 0px 0px 8px 1px rgb(177, 170, 170);
}
.navbar-brand{
  margin-left: 15px;
}
.head-navbar .navbar-nav {
  margin: auto;
}

.nav-item {
  margin: 0px 13px;
  color: rgba(0, 0, 0, .3)
}

.nav-item:hover .item {
  color:#e73c7e;
}
.navbar-toggler{
  padding: 3px 6px;
}
.navbar-toggler:focus{
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}
.dropdown:hover > .dropdown-menu {
  display: block;
  border: none;
  border-radius: 0px;
}

.dropdown-menu li:hover .dropdown-item {
  background-color: #e73c7e;
  color: white;
  font-weight: 500;
}
.active{
  color: blue;
}




.all-property{
    background-color: rgb(253, 248, 248);
    padding-top: 60px;
}
.all-property form{
    border: 1px solid rgba(0,0,0,.2);
    padding: 15px;
}

.search-button{
    padding: 8px 12px;
    margin-top: 30px;
    border-radius: 10px;
    color: white;
    border: none;
    background-color: #0ab6f5;
	background-position: top;
	background-repeat: repeat;
	background-size: auto auto;
}
.find-p input[type="text"]:focus,
.find-p select:focus
{
    outline: none;
}
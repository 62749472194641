.user-prop .card img{
    box-shadow: 0px 3px 10px rgba(228, 20, 20, 0.3);
    border-radius: 5px;
    height:250px;
}
.user-prop .card{
    padding: 10px;
    box-shadow: 0px 3px 10px rgba(24, 23, 23, 0.3);
    background-color: rgb(245, 236, 219);
}
.user-prop .card-text{
    font-size: 14px;
    font-weight: 400;
}
 .single-enquiry input[type="text"],
 .single-enquiry input[type="email"],
 .single-enquiry input[type="tel"],
 .single-enquiry textarea
 {
    width: 100%;
    border: 1px solid white;
    border-bottom: 1px solid #bebbbb;
    box-sizing: border-box;
    outline: none;
    border-radius: 0px;
    transition: all 0.30s ease-in-out;
}

.single-enquiry{
    height: auto;
    position: sticky;
    top: 50px;
    background-color: rgb(247, 236, 211);
    height: auto;
    box-shadow: 0px 1px 8px 1px grey;
    padding: 5px 10px 20px 10px;
}
.single-property-btn{
    width: 100%;
    border: none;
    padding: 10px 0px;
    background-color: rgb(243, 69, 38);
    color: white;
    font-weight: bold;
    font-size: 18px;
}
.cityproperty .card{
    border-radius: 0px;
    height: 100%;
}
.cityproperty .card .card-img-top{
    width: 100%;
    height: 20vw;
    object-fit: cover;
    opacity: .9;
}
@media screen and (min-width:768px) {
    .single-div{
        display: flex;
    }
}
@media screen and (max-width:768px) {
    .cityproperty .card .card-img-top{
        width: 100%;
        height: 30vw;
        object-fit: cover;
    }
}
@media screen and (max-width:576px) {
    .cityproperty .card .card-img-top{
        width: 100%;
        height: 40vw;
        object-fit: cover;
    }
}
@media screen and (max-width:992px) {
    .single-enquiry{
        max-height: 100%;
    }
    .single-property-btn{
        margin-bottom: 15px;
    }
}
#message_error{
    display: none;
    color: red;
}
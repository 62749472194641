.about-page-container .about-image{
    float: right;
    margin: 0px 0px 5px 15px;
}
.about-page-container{
    background-color: rgb(248, 242, 242);
}
.about-page-head{
    font-size: 1.8rem;
    font-family: GothamLight,sans-serif;
    color: #333!important;
}
.about-page-container .property-card{
    background-color: rgb(243, 232, 232);
    padding: 15px;
    opacity: .8;
    border-radius: 0px;
}
.about-page-container ul{
    font-size: 12px;
}
.about-page-container .card .list-group-item{
    border: 1px solid rgb(102, 97, 97);
    background-color:  rgb(218, 208, 208);
}
.about-page-container hr{
    margin: 10px 0px;
    color: red;
}
.about-page-container td{
    border: 1px solid black;
}

.about-page-container .link{
    text-decoration: none;
    margin-left: 15px;
    color:rgb(22, 22, 22);
    font-weight: 600;
}
.about-page-container .link:hover{
    color: blue;
}


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html{
    font-size: 14px;
   
}
body{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    
}
p{
font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
font-size: 14px;
}
.grid {
    display: grid;
    align-items: center;
    gap: .4rem;
}

.loginpage,.registerpage {
    padding: 20px 10px;
    border-radius: 10px;
    /* box-shadow: 0px 1px 5px 1px rgb(162, 163, 161); */
    background: rgb(162, 163, 161);
}


.footerDiv {
    position: absolute;
}

.showMessage{
    display: block;
    color: white;
    padding: 10px;
    background: red;
    border-radius: 3px;

}

.textDiv {
    position: relative;
}

.title-p {
    color: white;
    font-size: 35px;
    font-weight: 800;
}


.footerDiv {
    bottom: 10px;
    padding: 0.3rem 0rem;
    margin: 0 .5rem;
    background: rgba(94, 92, 92, 0.448);
    left: 0;
    right: 0;
    justify-content: space-between;
    backdrop-filter: blur(1px);
    border-radius: 10px;
    
}
.footerDiv .btn1{
    background: white;
    padding: 0 0px;
    color: rgb(11, 180, 11);
    padding: .3rem .3rem;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 500;
}
.footerDiv .btn1:hover{
    background: rgb(11, 180, 11);
    color: white;
}

.text {
    color: white;
    
}

.videoDiv  button.btn {
    padding: .8rem 1.5rem;
    border-radius: 12px;
    font-weight: 400;
    transition: .3s ease;
}

.formDiv{
    flex-basis: 50%;
    margin: auto;
    flex-direction: column;
    gap: 1.5rem;
    transform:  translateY(-1rem);
}
.headerDiv{
    text-align: center;
    /* padding: 1.5rem 0; */
}
.headerDiv h3{
    font-size: 25px;
    color: black;
    font-weight: 600;
}
.form{
    gap: 1rem;
}
.inputDiv input{
    background: none;
    outline: none;
    border: none;
}
.inputDiv label{
    color: black;
    font-weight: 500;
    font-size: 14px;
    padding: 0.5rem 0;
    display: block;
    text-align: justify;
}
.inputDiv .input{
    gap: .5rem;
    padding: .8rem;
    background: hsl(330, 12%, 97%);
    border-radius: 10px;
    text-align: justify;
}
.formDiv .btn{
    padding: 0.8rem;
    background: hsl(96, 90%, 33%) ;
    border-radius: 10px;
    color: white;
    font-size: 16px;
    transition: .3s ease; 
}
 .formDiv .btn .icon{
    font-size: 25px;
    transition: .3s ease;
}
.formDiv .btn:hover{
    background: hsl(96, 80%, 52%);
    color:black ;
}
 .formDiv .btn:hover .icon{
    color: black;
    transform: translateX(5px);
}

.forgotPassword{
    font-size: 14px;
    color: black;
}
.forgotPassword .click{
    font-weight: 600;
    color: blue;
}

@media screen and (max-width:576px){
   
    .footerDiv{
        /* position: relative; */
        /* top: 10%; */
        padding: 0;
        backdrop-filter: blur(0px);
    }
    .textDiv{
        position: absolute;
    }
    .headerDiv{
        margin-top: 20px;
    }
}
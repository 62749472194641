
/* {Form css} */
.content-1,.content-2,.content-3{
    border: 1px solid rgba(0, 0, 0, .1);
    margin-top: 15px;
    background-color: #fdfbea;
}
.sub-content{
    background-color: #315ea9;
    color: #fff;
    padding: 8px 5px;
}
.star{
    color: red;
}
small{
    color: red;
    font-size: 14px;
}
.input{
    border: 1px solid rgba(0, 0, 0, .2);
    width: 100%;
    padding: 7px;
}
.post-form .col-md-3{
    text-align: end;
}
.post-form{
    margin-top: 42px;
}
.post-form p{
    font-size: 13px;
    font-weight: 500;
}
.post-form label{
    font-size: 12px;
    font-weight: 400;
}
.post-form input[type=text],
.post-form input[type=tel]
{
    outline: none;
}

@media screen and (max-width:768px){
    .post-form .col-md-3{
        text-align: start;
    }
    .content-1,.content-2,.content-3{
        padding: 8px 10px;
    }
    .area .col-md-4{
        margin-top: 10px;
    }
}
@media screen and (max-width:315px) {
    .sub-content{
        display: block;
    }
}
.owner-contact{
    text-align: justify;
}
.owner-contact i{
    color: orangered;
    margin-bottom: 15px;
}
.owner-contact p{
    font-size: 14px;
    margin-left: 30px;
}
.owner-contact span{
    font-weight: 600;
}
.contact-container{
    background-color: rgb(241, 233, 233);
}
.contact-container h4{
    font-weight: 400;
    font-size: 1.6rem;
    color: orangered;
}
.cont-head span{
    color: blue;
}
.contact-container .contact-box{
    background-color: white;
    border-radius: 5px;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content:space-between;
}
.contact-form i{
    font-size: 8px;
    color: red;
    margin: 3px;
}
.contact-form label{
    font-size: 13px;
    font-weight: 400;
}

#name_error,#email_error,#phone_error,#country_error{
    display: none;
    color: red;
}
#country,#contact_for{
    width: 100%;
    border: 1px solid rgba(190, 183, 183, .5);
    padding: 6px 0px;
    border-radius: 5px;
}
#comments{
    width: 100%;
    border: 1px solid rgba(190, 183, 183, .5);
    border-radius: 5px;
    padding-left: 10px;
}
#comments:focus{
    outline: none;
}
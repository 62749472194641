.enquiry-headings{
    margin-top:5rem;
}
.enquiry-message h5{
    font-size: 16px;
    font-weight: 400;
    margin-top: 2rem;
}
.enquiry-message h6{
    font-size: 14px;
    font-weight: 300;
}
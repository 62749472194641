.form-head{
    display: flex;
    justify-content: space-between;
    background-color:#1c4ca0;
    color: white;
    padding: 10px 15px 0px 15px
}


.enquiry-head{
    color: rgb(25, 25, 168);
    opacity: .8;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
}

.enquiry-button{
    border: 1px solid rgba(0, 0, 0, .5);
    border-radius: 5px;
    background-color:#0380d4;
    font-weight: bold;
    padding: 7px 6px;
}
.enquiry-button:hover{
    background-color: rgb(7, 7, 7);
    color: white;
}
.clearfix:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
}

.form_wrap {
    background: #fff;
    box-sizing: border-box;
    padding: 15px;
    margin: 4% auto 0;
    position: relative;
    z-index: 1;
    display: none;
}
.button_wrap{
    display: flex;
    justify-content: center;
}
.form_wrap .form_wrap_head{
    display: flex;
    justify-content: space-between;
    background-color: blue;
    color: white;
    font-weight: 500;
    padding: 10px 10px 0px 10px;
}


.form_wrap h2 {
    font-size: 1.5em;
    line-height: 1.5em;
    margin: 0;
}

.form_wrap .title_container {
    text-align: center;
    /* margin: -15px -15px 15px; */
    padding: 15px 0;
}

.form_wrap h3 {
    font-size: 1.1em;
    font-weight: normal;
    line-height: 1.5em;
    margin: 0;
}

.form_wrap .row {
    margin: 10px -15px;
}

.form_wrap .row>div {
    padding: 0 15px;
    box-sizing: border-box;
}

.form_wrap .col_half {
    width: 50%;
    float: left;
}

.form_wrap label {
    display: block;
    margin: 0 0 5px;
    font-weight: 600;
}

.form_wrap .input_field,
.form_wrap .textarea_field {
    position: relative;
}

.form_wrap .input_field>span,
.form_wrap .textarea_field>span {
    position: absolute;
    left: 0;
    top: 0;
    color: #333;
    height: 100%;
    border-right: 1px solid #ccc;
    text-align: center;
    padding: 0 8px;
}

.form_wrap .textarea_field>span {
    border-bottom: 1px solid #ccc;
    max-height: 35px;
}

.form_wrap .input_field>span>i,
.form_wrap .textarea_field>span>i {
    padding-top: 12px;
}

.form_wrap input[type="text"],
.form_wrap input[type="email"],
.form_wrap input[type="tel"],
textarea,
select
 {
    width: 100%;
    padding: 10px 10px 10px 35px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    outline: none;
    transition: all 0.30s ease-in-out;
}

.form_wrap textarea {
    height: 8em;
}

.form_wrap input[type="text"]:focus,
.form_wrap input[type="email"]:focus,
.form_wrap input[type="tel"]:focus,
select:focus,
textarea:focus {
    box-shadow: 0 0 2px 1px rgba(255, 169, 0, 0.5);
    border: 1px solid #f5ba1a;
}

.form_wrap input[type="submit"] {
    background: orange;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.30s ease-in-out;
}

.form_wrap input[type="submit"]:hover,
.form_wrap input[type="submit"]:focus {
    background: blue;
}

.credit {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 15px;
    color: #f5ba1a;
}

.credit a {
    color: #daa106;
}

@media (max-width: 600px) {
    .form_wrap .col_half {
        width: 100%;
        float: none;
    }

    .form_wrap label {
        margin: 10px 0;
    }
}

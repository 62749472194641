
.by-city{
    margin-top: 50px;
}
.by-city .card{
    border-radius: 0px;
    height: 100%;
}
.by-city .card-img-top{
    width: 100%;
    height: 15vw;
    object-fit: cover;
    opacity: .9;
    border-radius: 0px;
}
.by-city .card-footer{
    background-color: #f5ba1a;
    color: rgb(32, 8, 240);
    font-weight: 500;
    border-radius: 0px;
}
.property-card p{
    font-size: 13px;
}
.about-page-container h4{
    color: orangered;
}
@media screen and (max-width:768px) {
    .by-city .card-img-top{
        width: 100%;
        height: 20vw;
        object-fit: cover;
    }
}
@media screen and (max-width:576px) {
    .by-city .card-img-top{
        width: 100%;
        height: 40vw;
        object-fit: cover;
    }
}
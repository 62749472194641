.footer{
    text-decoration: none;
    line-height: 1.86;
    font-size: 13px;
    font-weight: 400;
}
footer{
    margin-top: auto;
}
.footer p{
    font-size: 13px;
}
.list-group-item{
    border: none;
    opacity: .8;
    font-size: 14px;
    font-weight: 100;
}
.list-group-item:hover{
    opacity: 1;
}
.fab{
    font-size: 18px !important;
    transition: all ease-out .2s;
}
.fab:hover{
    transform: scale(1.2,1.2);
    color: rgb(49, 49, 235);
}
.fs-16{
    font-size: 16px;
    font-weight: 400;
}
.lh-18{
    font-size: 14px;
    font-weight: 100;
}
.lh-1{
    font-size: 13px;
}
/* @media screen and (max-width:576px) {
    .container-xxl{
        text-align: center;
   }
}  */